import {Directive, Input, OnDestroy, OnInit} from '@angular/core';
import {IonItem} from '@ionic/angular';
import {NavigationEnd, Router, RouterLink} from '@angular/router';
import {filter, map, tap} from 'rxjs/operators';
import {Subscription} from 'rxjs';


@Directive({
  selector: '[appIonRouterLinkActive]'
})
export class IonRouterLinkActiveDirective implements OnInit, OnDestroy {


  private handler: Subscription;

  constructor(private router: Router, private ionItem: IonItem, private rl: RouterLink) {
  }

  ngOnInit(): void {
    this.handler = this.router.events.pipe(
      filter(x => x instanceof NavigationEnd),
      map(() => this.rl && this.router.isActive(this.rl.urlTree, false)),
      tap(isActive => {
        this.ionItem.color = isActive ? 'secondary' : null;
      })
    ).subscribe();
  }
  ngOnDestroy() {
    this.handler.unsubscribe();
  }
}
