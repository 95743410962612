import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'baastv',
    loadChildren: () => import('./pages/baastv/baastv.module').then( m => m.BaastvPageModule)
  },
  {
    path: '',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)

  },
  {
    path: 'sickpics',
    loadChildren: () => import('./pages/sickpics/sickpics.module').then( m => m.SickpicsPageModule)
  },
  {
    path: 'radiobaas',
    loadChildren: () => import('./pages/radiobaas/radiobaas.module').then( m => m.RadiobaasPageModule)
  },
  {
    path: 'closeencounters',
    loadChildren: () => import('./pages/radiobaas/radiobaas.module').then( m => m.RadiobaasPageModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./pages/contact/contact.module').then( m => m.ContactPageModule)
  },
  {
    path: 'close-encounters',
    loadChildren: () => import('./pages/close-encounters/close-encounters.module').then( m => m.CloseEncountersPageModule)
  },
  {
    path: 'weigh-in',
    loadChildren: () => import('./pages/weigh-in/weigh-in.module').then( m => m.WeighInPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
