import { Component, HostListener } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { menuController } from '@ionic/core';
import { filter } from 'rxjs/operators';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  loggedIn=true;
  showLogin = false;
  pageTitle = 'WE. ARE. BAAS.';
  showFullToolbar = false;
  activeUrl = '';
  fullScreen = 1000;


  constructor(private titleService: Title, private router: Router) {
    this.titleService.setTitle(this.pageTitle);
    this.showFullToolbar = window.innerWidth>this.fullScreen;
    router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(x=>{
      this.activeUrl = this.router.url;
    });
  }
  @HostListener('ionScroll', ['$event'])
  scrolling($event: any) {
    const getScrollVal = $event;
    let scrollElement = getScrollVal.detail;
    console.log(scrollElement);
 }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.showFullToolbar = event.target.innerWidth>this.fullScreen;
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === 192) {
      this.showLogin=!this.showLogin;
      console.log(event);
    }
  }
  password(event){
    if(event.detail.value === 'sweeptheleg'){
      this.loggedIn=true;
    }
  }

}
