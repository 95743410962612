import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { Title }  from '@angular/platform-browser';
import { IonRouterLinkActiveDirective } from './directives/ion-router-link-active.directive';
import { LightgalleryModule } from 'lightgallery/angular';
import { FormsModule } from '@angular/forms';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";
import { HttpClientModule } from '@angular/common/http';

import { PhotomodalComponent } from './modal/photomodal/photomodal.component';
import { SocialsComponent } from './modal/socials/socials.component';
import { environment } from '../environments/environment';
@NgModule({
  declarations: [AppComponent, IonRouterLinkActiveDirective, PhotomodalComponent, SocialsComponent],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule, LightgalleryModule, FormsModule, RecaptchaV3Module, HttpClientModule],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, Title,
  { provide: RECAPTCHA_V3_SITE_KEY, useValue: "6LfCjsskAAAAAEpIBGBtEHAM4EN9TXo3eqrPtk2x" }],
  bootstrap: [AppComponent]
})
export class AppModule {}
