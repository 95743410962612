import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-photomodal',
  templateUrl: './photomodal.component.html',
  styleUrls: ['./photomodal.component.scss'],
})
export class PhotomodalComponent implements OnInit {
  @Input() url: string;
  showExitButton=false;
  constructor(private modelController: ModalController) { }

  ngOnInit() {
    this.showExitButton = window.innerWidth<700;
  }

  onClose(){
    this.modelController.dismiss();
  }

}
